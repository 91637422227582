
import Vue from 'vue';
import BaseButton from '@/components/BaseButton';

export default Vue.extend({
  name: 'ButtonComponent',
  components: {
    BaseButton,
  },
  props: {
    classes: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: Object,
    },
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    target: {
      type: String,
      default: '_self',
    },
    styles: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary', 'text-link'].includes(value),
    },
  },
  methods: {
    generateLink() {
      let link = this.href.href;

      if (this.href.cachedUrl) {
        link = `${this.href?.linkType === 'story' ? '/' : ''}${this.href.cachedUrl}`;
      }
      return link;
    },
  },
});
